#doc-page{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center
}
.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px 12px 16px;
    gap: 12px;
    box-shadow: 0px 5px 12px 0px rgba(226, 232, 240, 0.98);
    box-shadow: 0px 22px 22px 0px rgba(226, 232, 240, 0.85);
    background-color:#FFFFFF;
    gap: 12px;
    border-radius: 0px 0px 24px 24px;
    opacity: 0;
    margin-bottom: 24px;
}
.header-title{
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #0B1F33;
}
.logo-gos{
    width: 44px;
    height: 44px;
}
.doc-subtitle-block{
    display: flex;
    justify-content: space-between;
    padding: 0px 16px 0px 16px;
    gap: 0px;
}
.doc-subtitle{
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: #66727F;
}
.doc-item{
    display: flex;
    justify-content: space-between;
    padding: 12px 16px 12px 16px;
    gap: 8px;
    align-items: center;
}
.doc-name{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #0B1F33;
  }
.doc-date{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #66727F;
}
.doc-check{
    display: flex;
}
.doc-btn{
    height: 52px;
    background: #388DA8;
    border: 0;
    border-radius: 12px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 40px;
    width: 90%;
    padding: 14px 32px 14px 32px,
}

#docs-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
}
.doc-pdf{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 12px;
    padding: 12px;
    box-shadow: 0px 5px 12px 0px rgba(226, 232, 240, 0.98);
    box-shadow: 0px 22px 22px 0px rgba(226, 232, 240, 0.85);
    margin-bottom: 10px;
}

.signed-item{
    display: flex;
    padding: 8px 12px 8px 12px;
    border-radius: 12px;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
    box-shadow: 0px 5px 12px 0px rgba(226, 232, 240, 0.98);
    box-shadow: 0px 22px 22px 0px rgba(226, 232, 240, 0.85);
    margin-bottom: 10px;
}
.signed-date{
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: #66727F;
}

.signed-item1{
    display: flex;
    padding: 8px 12px 8px 12px;
    border-radius: 12px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.input-mail{
    height: 52px;
    padding: 8px 16px 8px 16px;
    border: 0px;
    gap: 2px;
    border-radius: 12px;
    opacity: 0;
    background-color: #E2E8F0;
}
.input-mail:focus {
    outline: none;
    box-shadow: 0 0 0 0.5px #388DA8;
}

#emptyDocList {
    margin: auto;
    width: 300px;
    text-align: center;
}

.centerDiv {
    display: flex !important;
    margin: auto !important;
}
.disDataBtn {
    background-color: #388DA8 !important;
    border-color: #388DA8 !important;
}

.disData-container {
    display: flex;
    flex-direction: column;
}

.disData-foot {
    margin-top: 10px;
    text-align: center;
    font-weight: 600;
}