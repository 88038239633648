#view-page{
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center
}
#view-block{
    width: 363px;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.view-subtitle{
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #66727F;
    margin-bottom: 0;
}
.view-title{
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #0B1F33;
    margin: 0;
}
.view-step-block{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}
.view-subtitle1{
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #66727F;
    margin-bottom: 0;
}
.step1{
    padding: 8px 4px 8px 4px;
    background-color: #E2E8F0;
    margin-right: 5px;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
}
.step2{
    padding: 8px 4px 8px 4px;
    background-color: #E2E8F0;
    margin-right: 5px;
}
.step3{
    padding: 8px 4px 8px 4px;
    background-color: #E2E8F0;
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
}

.view-btn{
    height: 52px;
    background: #388DA8;
    border: 0;
    border-radius: 12px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 40px;
    width: 100%;
    padding: 14px 32px 14px 32px,
}

.objRotate {
    margin-right: 20px;
    -webkit-animation-name: rotation;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: rotation;
    -moz-animation-duration: 1s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -o-animation-name: rotation;
    -o-animation-duration: 1s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
    animation-name: rotation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    
}

@-webkit-keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(720deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
#redirect-title {
    margin-bottom: 0;
}

#loader-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
