.modal-content{
    max-width: 365px !important;
}
.modal-dialog{
    display: flex !important;
    justify-content: center !important;;
}
.modal-header{
    background-color: #FFFFFF !important;
    border-bottom: 0px !important;
    padding: 24px !important;
    gap: 16px !important;
    color:#0B1F33;
    font-size: 16px !important;
    line-height: 24px !important;
}
.modal-body{
    background-color: #FFFFFF !important;
    border-bottom: 0px !important;
    padding: 0px 24px 24px 24px!important;
    gap: 16px !important;
}
.my-modal-title{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color:#66727F
}
.code-input-container {
    position: relative;
    width: 100%;
  }
  
  .code-input {
    width: 100%;
    height: 40px;
    padding: 0;
    border: none;
    font-size: 24px;
    text-align: center;
    opacity: 0;
    z-index: 1;
    caret-color: transparent !important; /* Скрытие каретки */
  }
  
  .visual-fields {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }
  
  .field {
    width: 65px;
    height: 52px;
    /* border: 1px solid #ccc; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    padding: 8px 16px 8px 16px;
    border-radius: 12px;
    background-color: #E2E8F0;
  }
  
  .field.filled {
    background-color: #f0f0f0;
  }
