.main-greet {
    display: flex;
    flex-direction: column;
    align-items: center;
}
#greeting{
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
#greeting-block{
    display: flex;
    flex-direction: column;
    align-items: center;

}
.logo-idgos{
    width: 150px;
    margin-bottom: 30px;
}
.greeting-title{
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}
.greeting-subtitle{
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    /* color: #66727F; */
}
.app-version {
    font-size: 10px;
    width: 90%;
    text-align: end;
}



