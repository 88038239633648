.App {
  text-align: center;
}
#root{
  max-width: 450px;
  margin: 0 auto 0 auto;
}

body{
  background-color: #eceff1 !important;
  position: relative;
}

a, a:hover, a:focus, a:active {
  text-decoration: none !important;
  color: inherit !important;
  all: unset !important;
  }

.radius {
  border-radius: 10px !important;
}

#documents-container {
  display: flex;
  flex-direction: column;
  width: 600px;
  min-width: 300px;
  margin: auto;
  padding: 20px 10px;
  justify-content: center;
  grid-auto-rows: minmax(200px, 300px);
}
@media(max-width: 600px) {
  #documents-container {
      width: 90vw;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#allowForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin: auto; */
  background-color: #fff;
  /* border-radius: 10px; */
  box-shadow: 0 0 15px #7a7a7a80;
}
#auth-wrapper {
  display: flex;
  flex-direction: column;
  width: 90vw;
  height: 400px;
  max-width: 300px;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 15px #7a7a7a80;
  margin-top: 15vh;

}
#auth-form-wrapper {
  padding: 20px 20px;
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 25px auto;
}
#allow-header-text {
  width: 100%;
  /* margin-bottom: 20px; */
  text-align: center;
  color: white;
  font-size: 18px;
  background-color: #3514ca;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
#auth-header-text {
  padding: 2rem 2rem;
  /* margin-bottom: 20px; */
  text-align: center;
  color: white;
  background-color: #3514ca;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}


.auth-input {
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  color: #495057;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  height: calc(1.5em + .75rem + 2px);
  line-height: 1.5;
  padding: .375rem .75rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  margin-bottom: 10px;
}
#allowCodeBtn,
#auth-btn {
  width: 100%;
  margin-top: 15px;
  padding: .75rem .75rem;
  background-color: #3514ca;
  border: none;
  border-radius: .25rem;
  text-align: center;
  color: white;
}
#allowCodeBtn {
  font-size: 18px;
}

#main-page-wrapper {
  height: 100vh;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  position: relative;
}

#main-page-nav {
  display: grid;
  grid-template-columns: 5fr 1fr;
  align-items: center;
  min-height: 50px;
  max-height: 70px; 
  padding: 0.375rem 1rem;
  background-color: #fff;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

#nav-esia-status {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
#nav-esia-status-icon {
  width: 40px;
}
#status-checked-icon {
  position: absolute;
  bottom: -4px;
  color: rgb(5, 175, 5);
}

#main-page-content-wrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
  min-width: 300px;
  margin: 20px auto;
  justify-content: center;
  grid-auto-rows: minmax(200px, 300px);
}

#gos-auth-container {
  display: flex;
  flex-direction: column;
  padding: 1em;
}
.gos-auth-warn {
  display: grid;
  grid-template-columns: 2fr 1fr;
}
#gos-auth-text {
  font-size: 14px;
  color: black;
  display: flex;
  align-items: center;
  font-weight: bold;
}
#gos-img-container {
  width: 100%;
  display: flex;
  justify-content: end;
}
#gos-img-content {
  width: 80px;
  height: 75px;
}
#gos-img {
  width: 100%;
}

.document {
  width: 90%;
  margin: 0 auto 20px auto;
  word-wrap: break-word;
  border: 2px;
  background-color: #ffffff4d;
  box-shadow: 4px 4px 20px 0 #0f0f0f1a;
  border-radius: 12px;
  padding: 10px;
  cursor: pointer;
  position: relative;
}

#nav-customer-info {
  font-weight: bold;
}

#gos-auth-btn {
  font-size: 14px;
  padding: 10px 20px;
  background-color: #3514ca;
  color: white;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  max-width: 200px;
}

.document-name {
  font-size: 14px;
  color: black;
}

.doc-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  
}
.status-title {
  font-size: 16px;
  color: black;
  font-weight: 600;
  margin-bottom: 10px;
}




#modal-btn {
  display: none;
}

#dissallow-btn,
#sign-btn {
  width: 95%;
  margin: 10px 2.5%;
  color: white;
  text-align: center;
  padding: 0.375rem 0.75rem;
}
#dissallow-btn {
  background-color: #ca141d;
}
#sign-btn {
  background-color: #3514ca;
}
.modal-warning-text {
  font-size: 16px;
  color: black;
  font-weight: bold;
  text-align: center;
}
#exampleModal {
  margin-top: 15vh;
}
#sign_loader,
#loader {
  /* display: flex; */
  position: absolute;
  width: 100%;
  /* max-width: 350px; */
  /* background-color: #080000c9; */
  height: 100vh;
  padding-top: 200px;
  z-index: 999;
}

.document-name {
  font-size: 14px;
  color: black;
}

.pdf-frame {
  position: absolute;
  width: 100%;
  margin: auto;
  top: 0px; 
  z-index: 999;
  height: 90vh;
}

.display-none {
  display: none !important;
}
.display-flex {
  display: flex;
}
.download-signed-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.download-signed {
  display: flex;
  width: 100%;
  max-width: 200px;
  justify-content: space-between;
  margin-top: 10px;
}
.download-doc {
  color: rgb(0, 122, 255)
}
.fa-download {
  margin-left: 5px;
}

.text-center {
    text-align: center;
}
.mt-100{
  margin-top:20%;
}

.progress {
    background-color: #e5e9eb;
    height: 0.45em;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    --bs-progress-font-size: aliceblue !important;
}
.loader-text {
  color: black;
}

.progress-bar {
  animation: loader 5s infinite ease-in-out;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-name: width;
  animation-name: width;
  background: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #6600ff, #6d56d6);
  background-size: 30em 0.25em;
  height: 100%;
  position: relative;
}
.progress-shadow {
    background-image: linear-gradient(to bottom, #eaecee, transparent);
    height: 4em;
    position: absolute;
    top: 100%;
    transform: skew(45deg);
    transform-origin: 0 0;
    width: 100%;
}

/* ANIMATIONS */
@keyframes width {
       0%, 100% {
       transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
  }
  0% {
       width: 0;
  }
   100% {
      width: 100%;
  }
}
@-webkit-keyframes width {
       0%, 100% {
       transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
  }
  0% {
       width: 0;
  }
   100% {
      width: 100%;
  }
}

#preview_btn {
  padding: 10px 15px;
  background: #3514ca;
  color: white;
  border-radius: 5px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 90% !important;
}
.modal-content {
  border-radius: 10px !important;
}
.modal-header {
  background: #3514ca !important;
}
.modal-body {
  background: #fff !important;
  padding: 0 !important;

}
.modal-footer {
  background: #fff !important;
  border-top: none !important;
}

.previewImgItem {
  margin-bottom: 10px;
}

.fixed-top{
  max-width: 450px;
  margin-right: auto;
  margin-left: auto;
}
.fixed-bottom {
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
}

.sinded-container {
  display: flex;
}
.signed-date {
  padding: 10px 0;
  margin-right: 20px;
}

.signed_container {
  /* min-width: 300px; */
  width: 90vw;
  margin: auto;
}

@media (min-width: 500px) {
  .signed_container {
    /* min-width: 300px; */
    max-width: 450px;
    margin: auto;
  }
}


.objRotate {
  margin-right: 20px;
  -webkit-animation-name: rotation;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: rotation;
  -moz-animation-duration: 1s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -o-animation-name: rotation;
  -o-animation-duration: 1s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
  animation-name: rotation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  
}

@-webkit-keyframes rotation {
  0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@-moz-keyframes rotation {
  0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@-o-keyframes rotation {
  0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(720deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.loader-text {
  margin-left: 20px;
}

